// var emitter = new EventEmitter();
import fbemitter from 'fbemitter';

const EventEmitter = new fbemitter.EventEmitter();

export const Events = {
  getFileInfo: 'get-file-info',
};

export default EventEmitter;
