import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { FailType, ProgressDocumentViewModel } from 'apis/nswag';

export interface IProgressInfo {
  message: string;
  progress: number;
  fileProgressId?: number;
}

interface State {
  open: boolean;
  progressInfo: IProgressInfo | null;
}

export const floatProgressSlice = createSlice({
  name: 'floatProgress',
  initialState: {
    open: false,
    progressInfo: null,
  } as State,
  reducers: {
    setFloatProgressInfo: (state: State, action: { payload: IProgressInfo | null }): void => {
      state.progressInfo = action.payload;
      state.open = !!state.progressInfo;
    },
  },
});

export const getFailErrorMessage = (progressInfo: ProgressDocumentViewModel) => {
  const errorMessage = i18next.t(progressInfo.failInfo as string);

  let moreInfoMessage = '';
  switch (progressInfo.failType) {
    case FailType.NumberStringFormatIncorrect:
      moreInfoMessage = i18next.t('importing.error.numberStringFormatIncorrect');
      break;
    case FailType.DateStringFormatIncorrect:
      moreInfoMessage = i18next.t('importing.error.dateStringFormatIncorrect');
      break;
  }

  let failMessage = `${i18next.t('failImportMessage')}. ${i18next.t('row')} ${
    progressInfo.failIndex
  } - ${errorMessage}. ${moreInfoMessage}`;

  return failMessage;
};

export const { setFloatProgressInfo } = floatProgressSlice.actions;

export default floatProgressSlice.reducer;
