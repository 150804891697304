import enResources from 'locales/en';
import viResources from 'locales/vi';

const langObject = {
  code: 'en',
  ns: 'common',
  languages: {
    en: 'en',
    vi: 'vi',
  },
};
const langResources = {
  [langObject.languages.en]: enResources,
  [langObject.languages.vi]: viResources,
};

export const appLang = langObject;
export const appLangResources = langResources;
