import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUnixTime } from 'date-fns';
import i18next from 'i18next';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { toastNotify } from 'app/notify/SnackbarUtils';

import { authClient, usersClient } from 'apis';
import { ILoginResponse } from 'apis/nswag';

import { roleKeys } from 'utils/roleMapping';

const loginInfoKey = 'loginInfo';

const getSavedAuthInfoFromLocalStorage = () => {
  const loginInfo =
    window.localStorage.getItem(loginInfoKey) && JSON.parse(window.localStorage.getItem(loginInfoKey) ?? '');
  return {
    ...loginInfo,
    verifyUser: false,
  };
};

const saveAuthInfoToLocalStorage = (data: any) => {
  window.localStorage.setItem(loginInfoKey, JSON.stringify(data));
};

const removeLoginInfoFromLocalStorage = () => {
  window.localStorage.removeItem(loginInfoKey);
};

export const refreshToken = createAsyncThunk('auth/refreshToken', async (data: any, thunkAPI) => {
  return await authClient.refreshToken(data);
});

export const verifyUser = createAsyncThunk('auth/verifyUser', async (data: any, thunkAPI) => {
  const response = await usersClient.getUserById(data);
  if (!response.isActive && response.role !== roleKeys.Employee) {
    toastNotify.error(i18next.t('deactivatedAccount'));
    throw new Error(i18next.t('deactivatedAccount'));
  }
  return response;
});

const loginResponse: ILoginResponse = {
  accessToken: undefined,
  refreshToken: undefined,
  user: undefined,
};

export type AuthState = ILoginResponse & {
  verifyUser: boolean;
};

const initialState: AuthState = {
  ...loginResponse,
  verifyUser: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    saveLoginInfo: (state, action: PayloadAction<ILoginResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      state.user = action.payload.user;
      state.verifyUser = true;

      saveAuthInfoToLocalStorage(action.payload);
    },
    logout: (state) => {
      state.accessToken = undefined;
      state.refreshToken = undefined;

      state.user = undefined;
      state.verifyUser = false;

      removeLoginInfoFromLocalStorage();
    },
  },
  extraReducers: (builder) => {
    // Refresh token
    builder
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;

        state.user = action.payload.user;
        state.verifyUser = true;

        saveAuthInfoToLocalStorage(action.payload);
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.accessToken = undefined;
        state.refreshToken = undefined;

        state.user = undefined;
        state.verifyUser = false;

        removeLoginInfoFromLocalStorage();
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.verifyUser = true;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.verifyUser = false;
        state.accessToken = undefined;
        state.refreshToken = undefined;
        state.user = undefined;
        removeLoginInfoFromLocalStorage();
      });
  },
});

export { getSavedAuthInfoFromLocalStorage, saveAuthInfoToLocalStorage };

// Selectors
export const authSelector = (state: any) => state.auth as AuthState;

export const isTokenExpiredSelector = (state: any) => {
  if (!state.auth.accessToken) return false;

  const { exp } = jwtDecode<JwtPayload>(state.auth.accessToken);
  if (!exp) return false;

  const isExpired = exp < getUnixTime(new Date());

  return isExpired;
};

export const tokenExpSelectr = (state: any) => {
  if (!state.auth.accessToken) return 0;

  const { exp } = jwtDecode<JwtPayload>(state.auth.accessToken);
  if (!exp) return 0;

  return exp;
};

export const { saveLoginInfo, logout } = authSlice.actions;
export default authSlice.reducer;
