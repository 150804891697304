import { appSettings } from 'app/appSettings';

import axiosInstance, { cancelToken } from './http';
import * as generatedApi from './nswag';

const apiInfoClient = new generatedApi.ApiInfoClient(appSettings.apiUrl, axiosInstance);
const exampleClient = new generatedApi.ExampleClient(appSettings.apiUrl, axiosInstance);
const authClient = new generatedApi.AuthClient(appSettings.apiUrl, axiosInstance);
const usersClient = new generatedApi.UsersClient(appSettings.apiUrl, axiosInstance);
const divisionClient = new generatedApi.DivisionClient(appSettings.apiUrl, axiosInstance);
const employerClient = new generatedApi.EmployerClient(appSettings.apiUrl, axiosInstance);
const producerClient = new generatedApi.ProducerClient(appSettings.apiUrl, axiosInstance);
const carriersClient = new generatedApi.CarrierClient(appSettings.apiUrl, axiosInstance);
const fileClient = new generatedApi.FileClient(appSettings.apiUrl, axiosInstance);
const clientsClient = new generatedApi.ClientClient(appSettings.apiUrl, axiosInstance);
const policyClient = new generatedApi.PolicyClient(appSettings.apiUrl, axiosInstance);
const afrRateClient = new generatedApi.AfrRateClient(appSettings.apiUrl, axiosInstance);
const documentClient = new generatedApi.DocumentClient(appSettings.apiUrl, axiosInstance);
const investmentsClient = new generatedApi.InvestmentClient(appSettings.apiUrl, axiosInstance);
const productsClient = new generatedApi.ProductClient(appSettings.apiUrl, axiosInstance);

export {
  cancelToken,
  apiInfoClient,
  exampleClient,
  authClient,
  usersClient,
  divisionClient,
  employerClient,
  producerClient,
  carriersClient,
  fileClient,
  clientsClient,
  policyClient,
  afrRateClient,
  investmentsClient,
  productsClient,
  documentClient,
};
