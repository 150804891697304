import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/hooks';
import { useAppDispatch } from 'app/hooks';
import { toastNotify } from 'app/notify/SnackbarUtils';
import { RootState } from 'app/store';

import { fileClient } from 'apis';

import { usePrompt } from 'hooks/usePrompt';

import EventEmitter, { Events } from 'utils/EventEmitter';

import { getFailErrorMessage, setFloatProgressInfo } from './floatProgressSlice';

const floatProgressSelectore = (state: RootState) => state.floatProgress;

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const { onMouseEnter, onMouseLeave, ...circularProgressProps } = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <CircularProgress {...circularProgressProps} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const FloatProgressRenderer = () => {
  const { open, progressInfo } = useAppSelector(floatProgressSelectore);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);
  const [progressCount, setProgressCount] = React.useState(0);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (progressInfo === null) {
      return;
    }

    const timer = setInterval(async () => {
      try {
        if (loading) {
          return;
        }
        if (!progressInfo.fileProgressId || progressInfo.progress >= 100 || progressCount > 8) {
          EventEmitter.emit(Events.getFileInfo);
          dispatch(setFloatProgressInfo(null));
          return;
        }

        setLoading(true);
        const nextProgressInfo = await fileClient.progress(progressInfo.fileProgressId as number);
        if (progressInfo.progress === nextProgressInfo.percentageOfCompletion) {
          setProgressCount(progressCount + 1);
        }
        if (nextProgressInfo.failType || nextProgressInfo.failInfo) {
          const failMessage = getFailErrorMessage(nextProgressInfo);
          toastNotify.error(failMessage);
          EventEmitter.emit(Events.getFileInfo);
          dispatch(setFloatProgressInfo(null));
        } else {
          dispatch(
            setFloatProgressInfo({
              message: `${nextProgressInfo.fileState} data`,
              progress: nextProgressInfo.percentageOfCompletion,
              fileProgressId: nextProgressInfo.id,
            })
          );
        }
        setLoading(false);
      } catch (e) {
        EventEmitter.emit(Events.getFileInfo);
        dispatch(setFloatProgressInfo(null));
      }
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch, loading, progressCount, progressInfo, t]);

  usePrompt('Changes you made may not be saved.', progressInfo !== null);

  const openPopover = Boolean(anchorEl);

  return (
    <>
      {open && progressInfo && (
        <Box
          sx={{
            position: 'fixed',
            right: 20,
            top: 80,
          }}
        >
          <CircularProgressWithLabel
            variant="determinate"
            value={progressInfo.progress}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
          />
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>
              {progressInfo.message}. {t('importNotice')}
            </Typography>
          </Popover>
        </Box>
      )}
    </>
  );
};

export default FloatProgressRenderer;
