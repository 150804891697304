import { AccountCircle } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, AppBar as MuiAppBar, Toolbar, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APP_ROUTES from 'app/consts/routes';
import { useAppSelector } from 'app/hooks';

import { authSelector, logout } from 'features/auth/authSlice';
import Setting from 'features/setting';

export default function AppBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectAuth = useAppSelector(authSelector);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());

    return navigate(`/${APP_ROUTES.Login.path}`, { replace: true });
  };

  const goProfile = async () => {
    return navigate(`/${APP_ROUTES.Profile.path}`);
  };

  const name = useMemo(() => {
    return `${selectAuth.user?.firstName || ''} ${selectAuth.user?.lastName || ''}`;
  }, [selectAuth]);

  return (
    <MuiAppBar position="absolute" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {/* Logo */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Simplicity Keystone
        </Typography>

        {/* Avatar */}
        <div>
          <Setting />
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem sx={{ cursor: 'default', ':hover': { bgcolor: '#FFF' } }} disableTouchRipple>
              {name}
            </MenuItem>
            <MenuItem onClick={goProfile}>My Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}
