import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

const addYupMethods = () => {
  yup.addMethod(yup.array, 'uniqueProperty', function (propertyPath: string, message: string) {
    return this.test('unique', '', function (list = []) {
      const errors: any[] = [];

      list.forEach((item, index) => {
        const propertyValue = _get(item, propertyPath);

        if (propertyValue && _filter(list, [propertyPath, propertyValue]).length > 1) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message,
            })
          );
        }
      });

      if (!_isEmpty(errors)) {
        throw new yup.ValidationError(errors);
      }

      return true;
    });
  });
};

declare module 'yup' {
  interface ArraySchema<T> {
    uniqueProperty(propertyPath: string, message?: any): ArraySchema<T>;
  }
}

addYupMethods();
