import { createSlice } from '@reduxjs/toolkit';

interface State {
  openBackdrop: boolean;
}

export const backdropSlice = createSlice({
  name: 'backdrop',
  initialState: {
    openBackdrop: false,
  },
  reducers: {
    setBackdrop: (state: State, action: { payload: boolean }): void => {
      state.openBackdrop = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBackdrop } = backdropSlice.actions;

export default backdropSlice.reducer;
