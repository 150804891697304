import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import { toastNotify } from 'app/notify/SnackbarUtils';
import { getDefaultPageByRole } from 'app/routerDefinition';

import { authClient, usersClient } from 'apis';
import { ILoginRequest, ILoginResponse, LoginRequest } from 'apis/nswag';

import { authSelector, isTokenExpiredSelector, saveLoginInfo } from './authSlice';

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectLogin = useSelector(authSelector);
  const isTokenExpired = useSelector(isTokenExpiredSelector);
  const isValidLoginInfo = selectLogin.accessToken && !isTokenExpired;

  const [showPassword, setShowPassword] = useState(false);

  const defaultFormData: ILoginRequest = {
    email: '',
    password: '',
  };

  const validationSchema = object({
    email: string().required('Email is required').email('Invalid email'),
    password: string().required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: defaultFormData,
  });

  const getHelperText = (name: string): string => {
    return errors[name as keyof ILoginRequest]?.message ?? ' ';
  };

  const onSubmit: SubmitHandler<ILoginRequest> = useCallback(
    async (data) => {
      try {
        usersClient['instance'].ignoreError = true;
        const loginResult = await authClient.authenticate(data as LoginRequest);

        if (loginResult) {
          dispatch(saveLoginInfo(loginResult as ILoginResponse));
          reset();
          navigate(getDefaultPageByRole(loginResult?.user?.role));
        }
      } catch (e: any) {
        if (!e.response) {
          toastNotify.error(e.title);
        }
      } finally {
        usersClient['instance'].ignoreError = false;
      }
    },
    [dispatch, navigate, reset]
  );

  const navigateToForgotPasswordPage = () => {
    navigate('/forgot-password');
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (isValid && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        event.preventDefault();
        handleSubmit(onSubmit)(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, isValid, onSubmit]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div>
      {/* Navigate to the default page if User has been logged in */}
      {isValidLoginInfo && <Navigate to={getDefaultPageByRole(selectLogin?.user?.role)} replace />}

      {/* Ask user to do login if they haven't do so */}
      <Grid container sx={{ height: '100vh' }}>
        <Card sx={{ m: 'auto', p: 3, width: 550 }}>
          <CardContent>
            <form>
              <Grid container spacing={2} direction="column">
                {/* Header */}
                <Grid item sx={{ textAlign: 'center', m: 2 }}>
                  <Typography sx={{ fontSize: 18, mb: 2 }}>Sign in to continue to:</Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: 18 }}>Simplicity Keystone Web Portal</Typography>
                </Grid>

                {/* Email */}
                <Grid item>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        placeholder="Email"
                        error={!!errors.email?.message}
                        helperText={getHelperText('email')}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>

                {/* Password */}
                <Grid item>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.password?.message}
                        helperText={getHelperText('password')}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>

                {/* Submit */}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Continue
                  </Button>
                </Grid>

                {/* Forgot Password */}
                <Grid item sx={{ textAlign: 'center' }}>
                  <Button variant="text" color="primary" onClick={navigateToForgotPasswordPage}>
                    Forgot Password?
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default Login;
