import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormGenerator } from './FormGenerator';

interface IProps {
  fieldGroups: any[];
  item?: { [key: string]: any };
  validationSchema: any;
  onSubmit?: (data: any) => void;
  onError: (errors: any, e: any) => void;
  onCancel?: () => void;
  submitButonLabel?: string | React.ReactNode;
}

export const AutoFormContainer = (props: IProps) => {
  const { fieldGroups, item = {}, validationSchema, onSubmit, onError, onCancel, submitButonLabel } = props;

  const { handleSubmit, ...formInstance } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { ...item },
  });

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (!!onSubmit) {
          handleSubmit(onSubmit)(event);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, onSubmit]);

  return (
    <FormControl fullWidth>
      <FormGenerator fieldGroups={fieldGroups} formInstance={formInstance} />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        {!!onSubmit && (
          <Button variant="contained" onClick={handleSubmit(onSubmit, onError)}>
            {submitButonLabel || 'Save'}
          </Button>
        )}
        {!!onCancel && (
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Stack>
    </FormControl>
  );
};

export default AutoFormContainer;
