import { LazyExoticComponent, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Login, { SetPassword } from 'features/auth';
import NotFound from 'features/notfound';

import RequireAuth from './RequireAuth';
import APP_ROUTES from './consts/routes';
import Layout from './layout';

const DialogExample = lazy(() => import('features/examples/DialogExample'));
const FormExample = lazy(() => import('features/examples/FormExample'));
const ForgotPassword = lazy(() => import('features/forgot-password'));
const Dashboard = lazy(() => import('features/dashboard'));
const UserList = lazy(() => import('features/users/UserList'));
const UserDetail = lazy(() => import('features/users/UserDetail'));
const EmployerList = lazy(() => import('features/employers/EmployerList'));
const EmployerDetail = lazy(() => import('features/employers/EmployerDetail'));
const CarrierList = lazy(() => import('features/carriers/CarrierList'));
const CarrierDetail = lazy(() => import('features/carriers/CarrierDetail'));
const CarrierImport = lazy(() => import('features/carriers/CarrierImport'));
const ImportFiles = lazy(() => import('features/import-files/ImportFiles'));
const ClientOverview = lazy(() => import('features/client/Overview'));
const PlanContributionsTab = lazy(() => import('features/users/components/PlanContributionsTab'));
const DocumentResource = lazy(() => import('features/users/components/DocumentsResourcesTab'));
const AfrEntry = lazy(() => import('features/afr-entry'));
const AccountsReceivable = lazy(() => import('features/account-receivable'));
const ReceivablesParticipant = lazy(() => import('features/account-receivable/ReceivablesParticipant'));

const renderPage = (PageComponent: LazyExoticComponent<(props?: any) => JSX.Element>) => (
  <Suspense fallback={<></>}>
    <PageComponent />
  </Suspense>
);

export const AppRouters = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.DialogExamples.path} element={renderPage(DialogExample)} />
      <Route path={APP_ROUTES.FormExamples.path} element={renderPage(FormExample)} />
      <Route path={APP_ROUTES.Login.path} element={<Login />} />
      <Route path={APP_ROUTES.SetPassWord.path} element={<SetPassword />} />
      <Route path={APP_ROUTES.ForgotPassword.path} element={renderPage(ForgotPassword)} />

      <Route path="/" element={<Layout />}>
        <Route index element={<RequireAuth>{renderPage(Dashboard)}</RequireAuth>} />
        <Route path={APP_ROUTES.Profile.path} element={<RequireAuth>{renderPage(UserDetail)}</RequireAuth>} />
        <Route path={APP_ROUTES.Dashboard.path} element={<RequireAuth>{renderPage(Dashboard)}</RequireAuth>} />
        <Route path={APP_ROUTES.Users.path}>
          <Route index element={<RequireAuth roles={APP_ROUTES.Users.roles}>{renderPage(UserList)}</RequireAuth>} />
          <Route
            path=":userId"
            element={<RequireAuth roles={APP_ROUTES.Users.roles}>{renderPage(UserDetail)}</RequireAuth>}
          />
        </Route>
        <Route path={APP_ROUTES.Organizations.path}>
          <Route
            index
            element={<RequireAuth roles={APP_ROUTES.Organizations.roles}>{renderPage(EmployerList)}</RequireAuth>}
          />
          <Route
            path=":employerId"
            element={<RequireAuth roles={APP_ROUTES.Organizations.roles}>{renderPage(EmployerDetail)}</RequireAuth>}
          />
        </Route>
        <Route path={APP_ROUTES.Carriers.path}>
          <Route
            index
            element={<RequireAuth roles={APP_ROUTES.Carriers.roles}>{renderPage(CarrierList)}</RequireAuth>}
          />
          <Route
            path={APP_ROUTES.ImportPath.path}
            element={<RequireAuth roles={APP_ROUTES.ImportPath.roles}>{renderPage(CarrierImport)}</RequireAuth>}
          />
          <Route
            path=":carrierId"
            element={<RequireAuth roles={APP_ROUTES.Carriers.roles}>{renderPage(CarrierDetail)}</RequireAuth>}
          />
        </Route>
        <Route
          path={APP_ROUTES.ImportFiles.path}
          element={<RequireAuth roles={APP_ROUTES.ImportFiles.roles}>{renderPage(ImportFiles)}</RequireAuth>}
        />
        <Route
          path={APP_ROUTES.ClientOverview.path}
          element={<RequireAuth roles={APP_ROUTES.ClientOverview.roles}>{renderPage(ClientOverview)}</RequireAuth>}
        />
        <Route
          path={APP_ROUTES.PlanContribution.path}
          element={
            <RequireAuth roles={APP_ROUTES.PlanContribution.roles}>{renderPage(PlanContributionsTab)}</RequireAuth>
          }
        />
        <Route
          path={APP_ROUTES.DocumentResource.path}
          element={<RequireAuth roles={APP_ROUTES.DocumentResource.roles}>{renderPage(DocumentResource)}</RequireAuth>}
        />
        <Route
          path={APP_ROUTES.AfrEntry.path}
          element={<RequireAuth roles={APP_ROUTES.AfrEntry.roles}>{renderPage(AfrEntry)}</RequireAuth>}
        />
        <Route path={APP_ROUTES.AccountsReceivable.path}>
          <Route
            index
            element={
              <RequireAuth roles={APP_ROUTES.AccountsReceivable.roles}>{renderPage(AccountsReceivable)}</RequireAuth>
            }
          />
          <Route
            path=":divisionId/:selectedId"
            element={
              <RequireAuth roles={APP_ROUTES.AccountsReceivable.roles}>
                {renderPage(ReceivablesParticipant)}
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <RequireAuth>
              <NotFound />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRouters;
