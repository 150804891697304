// import createSagaMiddleware from 'redux-saga';
// import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';

import authReducer, { getSavedAuthInfoFromLocalStorage } from 'features/auth/authSlice';
import backdropReducer from 'features/backdrop/backdropSlice';
import dialogReducer from 'features/dialog/dialogSlice';
import floatProgressReducer from 'features/float-progress/floatProgressSlice';
import languageReducer from 'features/languages/languageSlice';

// In case of we want to use redux-saga
// const sagaMiddleware = createSagaMiddleware();

// export const store = configureStore({
//   reducer: {
//     languages: languageReducer,
//   },
//   middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
// });

// sagaMiddleware.run(rootSaga);

export const store = configureStore({
  reducer: {
    languages: languageReducer,
    auth: authReducer,
    dialog: dialogReducer,
    floatProgress: floatProgressReducer,
    backdrop: backdropReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'auth/saveLoginInfo',
          'auth/refreshToken/fulfilled',
          'dialog/openDialog',
          'auth/verifyUser/fulfilled',
        ],
        ignoredPaths: ['auth.user', 'dialog'],
      },
    }),
  preloadedState: {
    auth: getSavedAuthInfoFromLocalStorage() ?? {},
  },
  devTools: { latency: 0 },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
