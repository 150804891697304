import { createSlice } from '@reduxjs/toolkit';

import { appLang } from './configurations';

export const languageSlice = createSlice({
  name: 'languages',
  initialState: {
    lang: appLang.code,
    ns: appLang.ns,
  },
  reducers: {
    setLangCode: (state: any, action: any): void => {
      state.lang = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLangCode } = languageSlice.actions;

export default languageSlice.reducer;
