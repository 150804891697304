import { SxProps } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

interface IProps {
  title?: string;
  titleStyle?: SxProps;
  customTitle?: React.ReactNode;
  children: React.ReactNode;
  headerStyle?: SxProps;
}

export const PageContainer = (props: IProps) => {
  const { title, titleStyle = {}, customTitle, children, headerStyle = {} } = props;

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          customTitle ? (
            customTitle
          ) : (
            <Typography variant="h1" sx={{ fontWeight: 600, fontSize: 30, ...titleStyle }}>
              {title}
            </Typography>
          )
        }
        sx={{ ...headerStyle }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default PageContainer;
