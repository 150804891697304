import { Fragment } from 'react';
import { UseFormReturn } from 'react-hook-form';

import FormInputSelector from './FormInputSelector';

interface IProps {
  fieldGroups: any[];
  formInstance: Omit<UseFormReturn<any>, 'handleSubmit'>;
}

export const FormGenerator = (props: IProps) => {
  const { fieldGroups, formInstance } = props;

  if (!fieldGroups || fieldGroups.length === 0) {
    return null;
  }

  const { control } = formInstance;

  return (
    <>
      {fieldGroups.map((group: any, index: number) => {
        const { fields } = group;
        return (
          <Fragment key={index}>
            {fields.map((field: any) => {
              const { id, name } = field;

              return <FormInputSelector key={id || name} field={field} control={control} />;
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default FormGenerator;
