import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';

interface IProps {
  label: string | React.ReactNode;
  value?: any;
  helperText?: string;
  options?: { [k: string]: any }[];
  fullWidth?: boolean;
  valueFieldName?: string;
  labelFieldName?: string;
  disabledFieldName?: string;
  [k: string]: any;
}

export const Select = React.forwardRef((props: IProps, ref) => {
  const {
    label,
    fullWidth = true,
    options = [],
    helperText,
    valueFieldName = 'value',
    labelFieldName = 'label',
    disabledFieldName = 'disabled',
    ...otherProps
  } = props;
  return (
    <TextField fullWidth={fullWidth} select label={label} helperText={helperText} inputRef={ref} {...otherProps}>
      {options.map((option) => (
        <MenuItem
          key={option[valueFieldName]}
          value={option[valueFieldName]}
          disabled={option[disabledFieldName] ?? false}
        >
          {option[labelFieldName]}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default Select;
