import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import AppBar from './AppBar';
import SideBar from './SideBar';

export const Layout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 2, mt: 6, overflow: 'auto' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
