import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#124257',
    },
    secondary: {
      main: '#0085ff',
    },
    success: {
      main: '#23cc24',
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: 36,
      fontWeight: 400,
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
    },
    caption: {
      fontSize: 13,
    },
    button: {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
    },
  },
  components: {
    // // Global component styles here
    // MuiButton: {
    //   defaultProps: {
    //     // Customized props here
    //   },
    // },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: red[700],
        },
      },
    },
  },
});
