import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';

const selectDialogs = (state: RootState) => state.dialog.dialogs;

const DialogsRenderer = () => {
  const dialogs = useAppSelector(selectDialogs);

  return (
    <>
      {dialogs &&
        dialogs.map((x: any) => {
          const { component: DialogComponent, id, props } = x;

          return <DialogComponent key={id} {...props} />;
        })}
    </>
  );
};

export default DialogsRenderer;
