import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import AppRouters from 'app/AppRouters';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';

import { BackdropRenderer } from 'features/backdrop';
import { DialogsRenderer } from 'features/dialog';
import { FloatProgressRenderer } from 'features/float-progress';
import { appLang } from 'features/languages';

import { defaultTheme } from './styles/defaultTheme';

const languagesSelector = (state: RootState) => state.languages;

const App = () => {
  const { i18n } = useTranslation();
  const languages = useAppSelector(languagesSelector);

  // listen state lang change will call i18n change language
  useEffect(() => {
    const crrLang = languages?.lang || appLang.code;
    if (crrLang !== i18n.language) {
      i18n.changeLanguage(crrLang, (error) => {
        if (error) console.log('Change language error:', error);
      });
    }
  }, [i18n, languages?.lang]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <BrowserRouter>
          <AppRouters />
          <DialogsRenderer />
          <FloatProgressRenderer />
          <BackdropRenderer />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
