import i18next from 'i18next';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import store from 'app/store';

import 'features/languages/i18n';

import 'utils/yupMethods';

import App from './App';
import { SnackbarUtilsConfigurator } from './app/notify/SnackbarUtils';

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Suspense fallback="loading">
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          autoHideDuration={3000}
        >
          <SnackbarUtilsConfigurator />
          <App />
        </SnackbarProvider>
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
