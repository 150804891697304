import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { authSelector, verifyUser } from '../features/auth/authSlice';
import { getDefaultPageByRole } from './routerDefinition';

const RequireAuth = ({ children, roles = [] }: { children: JSX.Element; roles?: string[] }) => {
  const dispatch = useDispatch();
  const selectAuth = useSelector(authSelector);

  const allowedRole = useMemo(() => {
    if (roles.length === 0) {
      return true;
    }

    return roles.includes(selectAuth.user?.role || '');
  }, [roles, selectAuth]);

  // Require authentication
  const isLoggedIn = selectAuth.accessToken;
  if (!isLoggedIn) {
    return <Navigate to="/login" replace={true} />;
  }

  if (!selectAuth.verifyUser) {
    dispatch(verifyUser(selectAuth.user?.id));
  }

  if (!allowedRole) {
    const defaultPage = getDefaultPageByRole(selectAuth.user?.role);
    return <Navigate to={defaultPage} replace={true} />;
  }

  return children;
};

export default RequireAuth;
