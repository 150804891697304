import { Settings } from '@mui/icons-material';
import { Box, Drawer, IconButton, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';

import { appLang, setLangCode } from 'features/languages';

const selectLanguages = (state: RootState) => state.languages;

export default function Setting() {
  const [langs, setLangs] = useState<string[]>([]);
  const [crrLang, setCrrLang] = useState<string>(appLang.code);
  const [anchorSetting, setAnchorSetting] = useState(false);
  const toggleDrawer = (open: boolean) => (event: any) => {
    setAnchorSetting(open);
  };
  const { t } = useTranslation();
  const languages = useAppSelector(selectLanguages);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLangs(Object.values(appLang?.languages || []));
    setCrrLang(languages.lang);
  }, [languages.lang]);
  const handleChange = (event: any) => {
    const code = event.target.value;
    setCrrLang(code);
    dispatch(setLangCode(code));
  };
  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer(true)}
        color="inherit"
      >
        <Settings />
      </IconButton>
      <Drawer anchor={'right'} open={anchorSetting} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            paddingTop: 11,
            paddingX: 2,
          }}
        >
          <label>{t('common:language')}</label>
          <Select
            sx={{
              marginTop: 1,
              width: '100%',
            }}
            value={crrLang}
            onChange={handleChange}
          >
            {langs.map((code: string, index) => (
              <MenuItem key={`lang-${index}`} value={code}>
                {t(`all:title.${code}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Drawer>
    </>
  );
}
