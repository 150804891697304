import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { toastNotify } from 'app/notify/SnackbarUtils';

import { usersClient } from 'apis';
import { SetNewPasswordRequest, UserViewModel, ValidateTokenRequest } from 'apis/nswag';

import AutoFormContainer from 'components/forms/AutoFormContainer';

const validationSchema = Yup.object({
  password: Yup.string()
    .required(i18next.t('requiredField'))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-])(?=.{8,})/,
      i18next.t('passwordField')
    ),
  confirmPassword: Yup.string()
    .required(i18next.t('requiredField'))
    .oneOf([Yup.ref('password')], i18next.t('confirmPasswordField')),
});

export const SetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<UserViewModel | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const userId = searchParams.get('userId');
  const token = searchParams.get('token');
  const resetPassword = searchParams.get('resetPassword');

  const onSubmit: SubmitHandler<any> = async (values: any) => {
    const request = SetNewPasswordRequest.fromJS({
      userEmail: user?.email,
      password: values.password,
      token: userToken,
    });
    const updatedUser = await usersClient.setNewPassword(request);
    toastNotify.success('Success');

    if (updatedUser) {
      navigate('/login', { replace: true });
    }

    setUser(updatedUser);
  };
  const onError = (errors: any, e: any) => console.log(errors, e);

  useEffect(() => {
    if (!userId || !token) {
      return;
    }

    const validateConfirmationToken = async () => {
      try {
        usersClient['instance'].ignoreError = true;
        const request = ValidateTokenRequest.fromJS({
          token,
          resetPassword: resetPassword === 'true',
        });
        const validatedUser = await usersClient.validateConfirmationToken(userId, request);
        setUserToken(token);
        setUser(validatedUser);
      } catch (e: any) {
        if (!e.response) {
          toastNotify.error(e.title);
          navigate('/login', { replace: true });
        }
      } finally {
        usersClient['instance'].ignoreError = false;
      }
    };

    validateConfirmationToken();
  }, [userId, token, navigate, location.pathname, resetPassword]);

  useEffect(() => {
    if (!user?.id || !user?.email) {
      return;
    }

    if (user.isActive && !userToken) {
      navigate('/login', { replace: true });
    } else {
      navigate(location.pathname, { replace: true });
    }
  }, [location.pathname, navigate, user, userToken]);

  if (!user) {
    return null;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const fieldGroups = [
    {
      fields: [
        {
          name: 'password',
          type: showPassword ? 'text' : 'password',
          label: 'New Password',
          required: true,
          rules: { require: true },
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          },
        },
        {
          name: 'confirmPassword',
          type: showConfirmPassword ? 'text' : 'password',
          label: 'Confirm New Password',
          required: true,
          rules: { require: true },
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          },
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        marginTop: 8,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        {t('setNewPassword')}
      </Typography>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <AutoFormContainer
            fieldGroups={fieldGroups}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onError={onError}
            submitButonLabel="Submit"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetPassword;
