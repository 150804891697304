import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Introduction = (props: any) => {
  const sideBarCurrentState = props.sideBarCurrentState;
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        overflow: 'hidden',
        textAlign: 'center',
        py: '8px',
      }}
    >
      {sideBarCurrentState && (
        <Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Questions? Contact Us:
          </Typography>
          <Typography variant="body1">Simplicity Keystone</Typography>
          <Typography variant="body1">(404) 907-1882</Typography>
          <Typography variant="body1">kbg.portal@simplicitygroup.com</Typography>
        </Box>
      )}
      {!sideBarCurrentState && (
        <Box>
          <Fab
            sx={{
              mx: 'auto',
            }}
            size="small"
            color="primary"
            onClick={() => props.onClickFloatButton()}
          >
            <ContactMailIcon />
          </Fab>
        </Box>
      )}
    </Box>
  );
};

export default Introduction;
