export interface RoleMapping {
  [key: string]: string;
}

export const roleKeys = {
  Admin: 'Admin',
  Employee: 'Employee',
  Producer: 'Producer',
  EmployerAdmin: 'EmployerAdmin',
};

export const roleMapping: RoleMapping = {
  [roleKeys.Employee]: 'Employee',
  [roleKeys.Admin]: 'Admin',
  [roleKeys.Producer]: 'Producer',
  [roleKeys.EmployerAdmin]: 'Employer Admin',
};

export default roleMapping;
