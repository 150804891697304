import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import i18next from 'i18next';

import { toastNotify } from 'app/notify/SnackbarUtils';
import store from 'app/store';

import { authClient } from 'apis';

import { AuthState, getSavedAuthInfoFromLocalStorage, logout, saveLoginInfo } from 'features/auth/authSlice';

import { LoginResponse, RefreshTokenRequest } from './nswag';

const axiosInstance = axios.create({
  timeout: 180000,
  headers: {
    'content-type': 'application/json',
  },
});

declare module 'axios' {
  export interface AxiosInstance {
    ignoreError: boolean;
  }
}

const requestInterceptor = (config: AxiosRequestConfig) => {
  const savedAuthInfo = getSavedAuthInfoFromLocalStorage() as AuthState;
  if (savedAuthInfo && savedAuthInfo.accessToken) {
    (config.headers as AxiosRequestHeaders)['Authorization'] = 'Bearer ' + savedAuthInfo.accessToken;
  }
  return config;
};

axiosInstance.interceptors.request.use(requestInterceptor, (error) => error);

let deferred!: Promise<LoginResponse>;
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }

    const errorResponse = error.response;

    if (errorResponse) {
      const originalRequest = error.config;
      if (originalRequest && originalRequest.url && originalRequest.url.includes('refresh-token')) {
        store.dispatch(logout());
        window.location.href = '/login';
      }
      const loginInfo = getSavedAuthInfoFromLocalStorage();
      if (
        originalRequest &&
        loginInfo &&
        loginInfo.accessToken &&
        loginInfo.refreshToken &&
        errorResponse.status === 401
      ) {
        try {
          const { accessToken, refreshToken } = loginInfo;
          deferred =
            deferred ||
            authClient.refreshToken({
              accessToken,
              refreshToken,
            } as RefreshTokenRequest);
          const resData = await deferred;
          store.dispatch(saveLoginInfo(resData));
          return axiosInstance(originalRequest);
        } catch {}
      }
    }

    if (!axiosInstance.ignoreError) {
      if (errorResponse && errorResponse.data && errorResponse.data.split) {
        const localeError = errorResponse.data.split && errorResponse.data.split('Locale: ')[1];
        toastNotify.error(
          localeError ? i18next.t(localeError) : 'Unexpected Error, please contact your system administrator.'
        );
      } else {
        toastNotify.error(error.message || 'Unexpected Error, please contact your system administrator.');
      }
      console.log(error);
    }

    if (
      error.request.responseType === 'blob' &&
      errorResponse.data instanceof Blob &&
      errorResponse.data.type &&
      errorResponse.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          errorResponse.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(errorResponse.data);
      });
    }

    return Promise.reject(error);
  }
);

export const cancelToken = axios.CancelToken;

export default axiosInstance;
