import { FormLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import Select from 'components/inputs/Select';

const commonFormControlProps = {
  margin: 'normal',
  fullWidth: true,
};

const commonTextFieldProps = {
  InputLabelProps: { shrink: true },
  size: 'small',
  ...commonFormControlProps,
};

interface IProps {
  field: any;
  control: any;
}

export const FormInputSelector = (props: IProps) => {
  const { field, control } = props;
  const [Component, setComponent] = useState<any | null>(null);
  const [componentProps, setComponentProps] = useState<any | null>(null);

  const {
    field: { onChange, onBlur, value, ref },
    fieldState,
  } = useController({
    name: field.name,
    control,
    rules: field.rules,
    defaultValue: field.defaultValue,
  });

  const { error } = fieldState;

  useEffect(() => {
    const { id, name, type, label, data, required, ...otherProps } = field;
    const commonProps = {
      id: id || name,
      name,
      label,
      type,
      required,
      error: Boolean(error),
      helperText: error?.message,
    };

    let inputProps: any;
    let FormInput: any;

    switch (type) {
      case 'select':
        FormInput = Select;
        inputProps = {
          options: data,
          onChange,
          onBlur,
          value,
          inputRef: ref,
          ...commonTextFieldProps,
          ...commonProps,
          ...otherProps,
        };
        break;
      case 'checkbox':
        FormInput = FormControl;
        inputProps = {
          ...commonFormControlProps,
          ...otherProps,
          children: (
            <FormControlLabel
              label={label}
              control={<Checkbox id={id || name} onChange={onChange} checked={value} inputRef={ref} />}
            />
          ),
        };
        break;
      case 'switch':
        FormInput = FormControl;
        inputProps = {
          ...commonFormControlProps,
          ...otherProps,
          children: (
            <FormControlLabel
              label={label}
              control={<Switch id={id || name} onChange={onChange} checked={value} inputRef={ref} />}
            />
          ),
        };
        break;
      case 'radio':
        FormInput = FormControl;
        inputProps = {
          ...commonFormControlProps,
          ...otherProps,
          children: (
            <>
              <FormLabel>{label}</FormLabel>
              <RadioGroup row name={name} onChange={onChange} value={value} ref={ref}>
                {(data ?? []).map((x: any) => {
                  return <FormControlLabel key={x.value} value={x.value} control={<Radio />} label={x.label} />;
                })}
              </RadioGroup>
            </>
          ),
        };
        break;
      default:
        FormInput = TextField;
        inputProps = {
          onChange,
          onBlur,
          value,
          inputRef: ref,
          ...commonTextFieldProps,
          ...commonProps,
          ...otherProps,
        };
        break;
    }

    setComponent(FormInput);
    setComponentProps(inputProps);
  }, [Component, control, error, field, onBlur, onChange, ref, value]);

  if (!Component || !componentProps) {
    return null;
  }

  return <Component {...componentProps} />;
};

export default FormInputSelector;
