import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FolderIcon from '@mui/icons-material/Folder';
import GroupIcon from '@mui/icons-material/Group';
import Person from '@mui/icons-material/Person';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import APP_ROUTES from 'app/consts/routes';

import { RouteItem } from 'types/RouteItem';

export const navigationItems: RouteItem[] = [
  {
    path: `/${APP_ROUTES.ClientOverview.path}`,
    title: 'Client Overview',
    iconComponent: Person,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.ClientOverview.roles,
  },
  {
    path: `/${APP_ROUTES.Users.path}`,
    title: 'Users',
    iconComponent: GroupIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.Users.roles,
  },
  {
    path: `/${APP_ROUTES.Organizations.path}`,
    title: 'Organizations',
    iconComponent: AssignmentIndIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.Organizations.roles,
  },
  {
    path: `/${APP_ROUTES.Carriers.path}`,
    title: 'Carriers',
    iconComponent: RequestQuoteIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.Carriers.roles,
  },
  {
    path: `/${APP_ROUTES.ImportFiles.path}`,
    title: 'Import Files',
    iconComponent: PostAddIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.ImportFiles.roles,
  },
  {
    path: `/${APP_ROUTES.AfrEntry.path}`,
    title: 'AFR Entry',
    iconComponent: AnalyticsIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.AfrEntry.roles,
  },
  {
    path: `/${APP_ROUTES.AccountsReceivable.path}`,
    title: 'Accounts Receivable',
    iconComponent: AccountBalanceWalletIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.AccountsReceivable.roles,
  },
  {
    path: `/${APP_ROUTES.PlanContribution.path}`,
    title: 'Plan Contributions',
    iconComponent: AssignmentIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.PlanContribution.roles,
  },
  {
    path: `/${APP_ROUTES.DocumentResource.path}`,
    title: 'Documents & Resources',
    iconComponent: FolderIcon,
    component: '',
    exact: undefined,
    roles: APP_ROUTES.DocumentResource.roles,
  },
];

export const getAllowedNavigationItems = (role: string = '') => {
  const allowedNavigationItems = navigationItems.filter((route) => {
    if (!route.roles) {
      return true;
    }
    return route.roles.includes(role);
  });

  return allowedNavigationItems;
};

export const getDefaultPageByRole = (role: string = '') => {
  const allowedNavigationItems = getAllowedNavigationItems(role);
  return allowedNavigationItems[0]?.path || '/';
};
