import { roleKeys } from 'utils/roleMapping';

const APP_ROUTES = {
  DialogExamples: {
    path: 'dialog-examples',
    roles: [],
  },
  FormExamples: {
    path: 'form-examples',
    roles: [],
  },
  Login: {
    path: 'login',
    roles: [],
  },
  SetPassWord: {
    path: 'set-password',
    roles: [],
  },
  ForgotPassword: {
    path: 'forgot-password',
    roles: [],
  },
  Dashboard: {
    path: 'dashboard',
    roles: [],
  },
  Users: {
    path: 'users',
    roles: [roleKeys.Admin, roleKeys.Producer, roleKeys.EmployerAdmin],
  },
  Organizations: {
    path: 'organizations',
    roles: [roleKeys.Admin, roleKeys.Producer],
  },
  Carriers: {
    path: 'carriers',
    roles: [roleKeys.Admin],
  },
  ImportPath: {
    path: 'import',
    roles: [roleKeys.Admin],
  },
  ImportFiles: {
    path: 'import-files',
    roles: [roleKeys.Admin],
  },
  ClientOverview: {
    path: 'client-overview',
    roles: [roleKeys.Employee],
  },
  PlanContribution: {
    path: 'plan-contributions',
    roles: [roleKeys.Employee],
  },
  DocumentResource: {
    path: 'documents-resources',
    roles: [roleKeys.Employee],
  },
  AfrEntry: {
    path: 'afr-entry',
    roles: [roleKeys.Admin],
  },
  AccountsReceivable: {
    path: 'account-receivable',
    roles: [roleKeys.Admin, roleKeys.EmployerAdmin],
  },
  Profile: {
    path: 'my-profile',
    roles: [],
  },
};

export default APP_ROUTES;
