import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';

const openBackdropSelector = (state: RootState) => state.backdrop.openBackdrop;

const BackdropRenderer = () => {
  const openBackdrop = useAppSelector(openBackdropSelector);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={openBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropRenderer;
